




















































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Validation from "@/components/Validation.vue";
import Datepicker from "@/components/Datepicker.vue";
import { Validations } from "vuelidate-property-decorators";
import { required, maxLength } from "vuelidate/lib/validators";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { IStudent } from "@/interfaces/IStudent";
import { IInstructor } from "@/interfaces/IInstructor";
import { mixins } from "vue-class-component";
import BaseMixin from "@/mixins/BaseMixin";
import { IGroup } from "@/interfaces/IGroup";
import { ITodo } from "@/interfaces/ITodo";
import FscMultiselect from "@/components/FscMultiselect.vue";
import UserService from "@/services/UserService";
import { CREATE } from "@/constants/FormType";
import { IBasicUser } from "@/interfaces/IBasicUser";

@Component({
  components: {
    FscMultiselect,
    Datepicker,
    Validation,
    FscSimpleCard,
    SaveButton,
    AbortButton,
  },
})
export default class TodoForm extends mixins(BaseMixin) {
  public name = "TodoForm";

  public dueDate: string | null = null;
  private status: IBasicNamedDTO | null = null;
  private subject: string | null = null;
  private task: string | null = null;
  public author: IBasicUser | null = null;
  public user: IBasicUser | null = null;
  public student: IBasicNamedDTO | null = null;
  private priority: IBasicNamedDTO | null = null;
  private group: IGroup | null = null;
  private id: number | null = null;

  @Prop()
  public data!: ITodo;

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  @Prop({ type: String })
  public headerTitle: any;

  @Prop({ type: Array, default: () => [], required: true })
  public students!: Array<IStudent>;

  @Prop({ type: Array, default: () => [], required: true })
  public priorities!: Array<IBasicNamedDTO>;

  @Prop({ type: Array, default: () => [], required: true })
  public instructors!: Array<IInstructor>;

  @Prop({ type: Array, default: () => [], required: true })
  public statuses!: Array<IBasicNamedDTO>;

  @Prop({ type: Array, default: () => [], required: true })
  public groups!: Array<IGroup>;

  @Prop({ type: String, required: true })
  public type!: string;

  @Prop({ type: Boolean, required: false, default: () => false })
  public putLoading!: boolean;

  public subjectMaxCharacter = 100;
  public subjectRemainingText = "";
  public taskMaxCharacter = 500;
  public taskRemainingText = "";

  public subjectRemainingCharCount() {
    if (this.subject && this.subject.length > this.subjectMaxCharacter) {
      this.subjectRemainingText = "Limit von " + this.subjectMaxCharacter + " zeichen überschritten!";
    } else if (this.subject) {
      let remainCharacters = this.subjectMaxCharacter - this.subject.length;
      this.subjectRemainingText = "Verbleibende " + remainCharacters + " zeichen";
    }
  }

  public taskRemainingCharCount() {
    if (this.task && this.task.length > this.taskMaxCharacter) {
      this.taskRemainingText = "Limit von " + this.taskMaxCharacter + " zeichen überschritten!";
    } else if (this.task) {
      let remainCharacters = this.taskMaxCharacter - this.task.length;
      this.taskRemainingText = "Verbleibende " + remainCharacters + " zeichen";
    }
  }

  public onSubmit(): void {
    if (this.hasNotPermissionWrite) {
      return;
    }
    this.$v.$touch();

    if (this.$v.$invalid) return;

    const data = {
      user: this.user,
      priority: this.priority,
      dueDate: this.dueDate,
      author: this.author,
      subject: this.subject,
      status: this.status,
      task: this.task ? this.task : "",
      group: this.group,
      student: this.student,
      id: this.id,
    };

    this.$emit("on-submit", data);
  }

  @Watch("data", { immediate: true, deep: true })
  public init(data: ITodo): void {
    const user: IInstructor = UserService.getUser();
    if (data) {
      this.dueDate = data.dueDate || null;
      this.status = data.status || null;
      this.subject = data.subject || null;
      this.task = data.task || null;
      this.author = this.isTypeCreate ? user : data.author;
      this.user = data.user || null;
      this.student = data.student || null;
      this.priority = data.priority || null;
      this.group = data.group || null;
      this.id = data.id || null;
    }
  }

  public onAbort(): void {
    this.$emit("abort");
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      dueDate: { required },
      priority: { required },
      author: { required },
      user: { required },
      status: { required },
      subject: { required, maxValue100: maxLength(100) },
      task: { maxValue500: maxLength(500) },
    };
  }

  public get isTypeCreate(): boolean {
    return this.type === CREATE;
  }

  public get hasNotPermissionWrite(): boolean {
    return !this.$can(this.MODULE_TASK_WRITE);
  }
}
